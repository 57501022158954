import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CompanyIcon from './CompanyIcon.jsx';
import './SignInCert.css';
import { MuiFileInput } from 'mui-file-input'
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import JSZip from 'jszip';
import Link from '@mui/material/Link';

const dateFormatRu = new Intl.DateTimeFormat("ru");

function App() {

  const [certsList, setCertsList] = useState([]);
  const [selectedCert, setSelectedCert] = useState();
  const [submitOn, setSubmitOn] = useState();
  const [loading, setLoading] = useState();
  const [selectedFiles, setSelectedFiles] = useState(null)
  const [bDetached, setDetached] = useState(true);

  const onChangeCert = (ev, oCert) => {
    setSelectedCert(oCert);
    setSubmitOn(true);
    console.log(oCert);
  }

  const handleSubmit = ev => {
    ev.preventDefault();
    setSubmitOn(false);
    setLoading(true);
    fRun();
  };

  const fTest = ev => {
    const zip = new JSZip();
    zip.file("hello.txt", "Hello World\n");
    zip.file("hello.txt").async("string").then(d => console.log(d));
    // fGetCades(async pCades => {
    //   var oCadesAbout = await pCades.CreateObjectAsync("CAdESCOM.About");
    //   var oLicense = await pCades.CreateObjectAsync("CAdESCOM.CPLicense");
    //   var licType = await oLicense.Type();
    //   var validTo = await oLicense.ValidTo(pCades.CADESCOM_PRODUCT_TSP);
    //   var serialNumber = await oLicense.SerialNumber();
    //   console.log(serialNumber);
    // })
  }

  const fGetCades = fPromise => {
    const cadesplugin = window.cadesplugin;
    cadesplugin.then(function () {
      cadesplugin.async_spawn(function* (args) {
        try {
          return fPromise(cadesplugin);
        } catch(er) {
          const err = cadesplugin.getLastError(er);
          console.log((err.indexOf("0x80090019") + 1) ? 'No plugin' : err);
        }
      })
    }, function(error) {
      console.log(error);
    })
  }
  
  const fSetCertsList = () => fGetCades(async pCades => {
    const pStore = await pCades.CreateObjectAsync("CAdESCOM.Store");
    await pStore.Open();
    const oCertificates = await pStore.Certificates;
    const nCertsCount = await oCertificates.Count;
    const aCertsInfo = [];
    for (let i = 0; i < nCertsCount; i) {
      const oCertificate = await oCertificates.Item(++i);
      const oSubjectName = await oCertificate.SubjectName;
      const dFrom = await oCertificate.ValidFromDate;
      const dExpire = await oCertificate.ValidToDate;
      const oCertInfo = oSubjectName.split(', ').reduce((obj, pair) => {
        const [key, val] = pair.split('=');
        obj[key] = val && val.replace(/"/g, '');
        return obj;
      }, {});
      oCertInfo.nIndex = i;
      oCertInfo.oCert = oCertificate;
      oCertInfo.from = dateFormatRu.format(new Date(Date.parse(dFrom)));
      oCertInfo.expire = dateFormatRu.format(new Date(Date.parse(dExpire)));
      oCertInfo.serial = await oCertificate.SerialNumber;
      aCertsInfo.push(oCertInfo);
      // console.log(oCertificate);
    }
    // console.log(aCertsInfo);
    setCertsList(aCertsInfo);
    await pStore.Close();
  });

  const renderOption = (props, option) => <Box component="li" {...props}>
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      sx={{ my: 2 }}
    >
      <h4 style={{ margin: '8px 0' }}>
        <CompanyIcon color="secondary" uric={option.O} />
        {option.CN}
      </h4>
      <div>{option['ИНН ЮЛ'] && 'ИНН ЮЛ ' + option['ИНН ЮЛ']}</div>
      <div>{option.SN} {option.G}</div>
      <div>{option.T || null}</div>
      <div>{'ИНН ' + option['ИНН']}</div>
      <div>{'СНИЛС ' + option['СНИЛС']}</div>
      <div>{'Сертификат ' + option.serial}</div>
      <div>Действует с {option.from} по {option.expire}</div>
    </Grid>
  </Box>

  const fRun = async () => {
    const zip = new JSZip();
    var CADESCOM_CADES_BES = 1;
    var CADESCOM_CADES_X_LONG_TYPE_1 = 0x5d;
    var CADESCOM_BASE64_TO_BINARY = 1;

    await Promise.all(selectedFiles.map(async oFile => {
      const oFReader = new FileReader();
      oFReader.readAsDataURL(oFile);
      const oFREvent = await new Promise(resolve => oFReader.onload = resolve);
      const header = ";base64,";
      const sFileData = oFREvent.target.result;
      const sBase64Data = sFileData.substr(sFileData.indexOf(header) + header.length);
      const cadesplugin = window.cadesplugin;
      await cadesplugin.async_spawn(function* (args) {
        const oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
        yield oSigner.propset_Certificate(selectedCert.oCert);
        yield oSigner.propset_CheckCertificate(true);
        // yield oSigner.propset_TSAAddress("http://pki.tax.gov.ru/tsp/tsp.srf");

        const oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
        yield oSignedData.propset_ContentEncoding(CADESCOM_BASE64_TO_BINARY);
        yield oSignedData.propset_Content(sBase64Data);

        try {
          const sSignedMessage = yield oSignedData.SignCades(oSigner, CADESCOM_CADES_BES, bDetached);
          zip.file(oFile.name, sBase64Data, {base64: true});
          zip.file(oFile.name + '.sig', sSignedMessage);
        } catch (err) {
          alert("Failed to create signature. Error: " + cadesplugin.getLastError(err));
          return;
        }
      })
    }));
    const contentBlob = await zip.generateAsync({type:"blob"});
    let link = document.createElement('a');
    link.download = selectedFiles[0].name + '.zip';
    link.href = URL.createObjectURL(contentBlob);
    link.click();
    URL.revokeObjectURL(link.href);
    setSubmitOn(true);
    setLoading(false);
  }

  useEffect(() => {
    fSetCertsList();
  }, []);

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit}
      sx={{ m: 'auto', width: 1, maxWidth: 400 }}
    >
      <MuiFileInput
        fullWidth
        multiple
        value={selectedFiles}
        onChange={newFiles => setSelectedFiles(newFiles)}
        sx={{ my: 3 }}
        label='Выберите файл(ы)'
        placeholder='Нажмите на это поле для выбора'
      />
      <Autocomplete
        fullWidth
        disableClearable
        id="combo-box-certs"
        options={certsList}
        onChange={onChangeCert}
        getOptionLabel={(option) => option.CN}
        renderOption={renderOption}
        renderInput={(params) => <TextField {...params} label="Выберите сертификат" />}
      />
      <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 3 }}>
        <Typography>Прикреплённая</Typography>
        <Switch
          checked={bDetached}
          onChange={ev => setDetached(ev.target.checked)}
        />
        <Typography>Откреплённая</Typography>
      </Stack>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 4 }}
        className={loading ? 'crpt-load' : ''}
        disabled={!submitOn}
      >
        {loading ? '' : 'Подписать'}
      </Button>
      <Link
        color="inherit"
        href="https://e-trust.gosuslugi.ru/#/portal/sig-check"
        target="_blank"
        rel="noopener"
      >
        Ссылка для проверки подписи на Госуслугах
      </Link>
      <Button
        fullWidth
        variant="contained"
        sx={{ my: 3 }}
        onClick={fTest}
        style={{ display: 'none' }}
      >
        Test
      </Button>
    </Box>
  );
}

export default App;
